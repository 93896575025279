import { Containery } from "../../../components/global/Container/container";

const DiscountEditor = () => {
    return (
        <Containery>
            Hello
        </Containery>
    );
}

export default DiscountEditor;