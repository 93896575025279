const OrderCard = (p) => {
    return (
        <div className="order-card-container flex">
            <div className="tablecolormarker" style={{backgroundColor: p.tableColor}}></div>
            <div className="order-card-body">
                <p className="order-number titleP700"> { p.orderNumber } </p>
                <p className="p3 titleP500 mb-2"> <span>Total Items:</span> { p.totalItems } </p>
                <p className="p3 titleP500 mb-2"> <span>Table:</span> { p.tableNumber } </p>
            </div>
        </div>
    );
}

export default OrderCard;