import React, { useEffect, useState, useContext } from "react";
import './aside.css';
import Logo from '../../../assets/icons/logo.png';
import LogoD from '../../../assets/icons/logoD.png';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import mainContext from "../../../context/main";
import Icons from "../../../assets/icons";
import localeContext from "../../../locale/localeContext";



export const Aside = () =>{

    const conx = useContext(mainContext);
    const location = useLocation();
    const locale = useContext(localeContext);
    const t = locale.selectedLanguge.aside;

    useEffect(() => {
        const pageName = (location.pathname).split("/")[1];
        // handle active aside item ( on route change )
        const element = document.querySelector(`[data-route-name="/${pageName}"]`);
        handle_asideItems(false, element);
    }, [location]);

    useEffect(()=>{
        // elemnts
        const asidep = document.getElementsByClassName("asidep");
        const asideItemIcon = document.getElementsByClassName("asideItemIcon");
        const menu = document.getElementById("menu");
        const divTitle = document.getElementById("divTitle");
        const adminTit = document.getElementById("adminTit");
        const asideHeader = document.getElementById("asideHeader");
        const mainHeader = document.getElementById("mainHeader");
        const mainContainer = document.getElementById("mainContainer");
        const logoimg = document.getElementById("logoimg");

        const asideFunc = function(){
            if (window.innerWidth < 1200) {
                for (let i = 0; i < asidep.length; i++) {
                    asidep[i].classList.add("asidepAus");
                }
                for (let i = 0; i < asideItemIcon.length; i++) {
                    asideItemIcon[i].classList.add("asideItemIconAus");
                }
                menu.classList.add("menuAus");
                divTitle.classList.add("divTitleAus");
                adminTit.classList.add("adminTit");
                asideHeader.classList.add("asideHeaderAus");
                mainHeader.classList.add("mainHeaderAus");
                mainContainer.classList.add("mainContainerAus");
                logoimg.classList.add("logoimgAus");
            }else{
                for (let i = 0; i < asidep.length; i++) {
                    asidep[i].classList.remove("asidepAus");
                }
                for (let i = 0; i < asideItemIcon.length; i++) {
                    asideItemIcon[i].classList.remove("asideItemIconAus");
                }
                menu.classList.remove("menuAus");
                divTitle.classList.remove("divTitleAus");
                adminTit.classList.remove("adminTit");
                asideHeader.classList.remove("asideHeaderAus");
                mainHeader.classList.remove("mainHeaderAus");
                mainContainer.classList.remove("mainContainerAus");
                logoimg.classList.remove("logoimgAus");
            }
        }

        window.addEventListener("resize", function(){
            asideFunc();
        });

        if (window.innerWidth < 1200) {
            asideFunc();
        }
    },[]);

    const closeAside = () => {
        // elemnts
        const asidep = document.getElementsByClassName("asidep");
        const asideItemIcon = document.getElementsByClassName("asideItemIcon");
        const menu = document.getElementById("menu");
        const divTitle = document.getElementById("divTitle");
        const adminTit = document.getElementById("adminTit");
        const asideHeader = document.getElementById("asideHeader");
        const mainHeader = document.getElementById("mainHeader");
        const mainContainer = document.getElementById("mainContainer");
        const logoimg = document.getElementById("logoimg");
        for (let i = 0; i < asidep.length; i++) {
            asidep[i].classList.add("asidepAus");
        }
        for (let i = 0; i < asideItemIcon.length; i++) {
            asideItemIcon[i].classList.add("asideItemIconAus");
        }
        menu.classList.add("menuAus");
        divTitle.classList.add("divTitleAus");
        adminTit.classList.add("adminTit");
        asideHeader.classList.add("asideHeaderAus");
        mainHeader.classList.add("mainHeaderAus");
        mainContainer.classList.add("mainContainerAus");
        logoimg.classList.add("logoimgAus");
    }

    const handle_asideItems = (e, element) => {
        // elemnts
        const item = element || e.currentTarget; // if element is passed then use it else use e.currentTarget
        const ID = item && item.id;
        const asidep = document.querySelectorAll(".asidep");
        const asideItemIcon = document.querySelectorAll(".asideItemIcon");
        // remove active class from all items
        asidep.forEach(elem =>  elem.classList.remove("asideActive") )
        asideItemIcon.forEach(elem =>  elem.classList.remove("asideActive") )

        if(!item) return
        // add active class to target item
        let items = [
            document.querySelector(`#${ID} .asidep`) ,
            document.querySelector(`#${ID} .asideItemIcon`)
        ];
        items.map( elem =>  elem.classList.toggle("asideActive"));
    }




    return(
        <aside className="menu" id="menu">
            <div className="asideHeader d-flex align-items-center justify-content-between" id="asideHeader">
                <div className="d-flex align-items-center">
                    {   conx.state.darkMood 
                        ?  <img src={Logo} alt="logo" className="logoimg" id="logoimg" /> 
                        : <img src={LogoD} alt="logo" className="logoimg" id="logoimg" /> 
                    }
                    <span className="p1" id="adminTit"></span>
                </div>

                <div className="CloseAsideContainer" onClick={closeAside}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" strokeWidth="2" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round" style={{stroke: "var(--black)" , cursor: "pointer" }}>
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                </div>
            </div>

            <div className="aside-body">
                <p className="divTitle" id="divTitle">Menu</p>
                <Link to="/" className="Link">
                <div className="asideItem d-flex align-items-center" onClick={handle_asideItems} id="DashboardItem" data-route-name="/">
                    <Icons.Home />
                    <span className="p1 asidep asideActive"> {t.dashboard} </span>
                </div>
                </Link>

                <Link to="/orders" className="Link">
                <div className="asideItem d-flex align-items-center" onClick={handle_asideItems} id="OrdersItem" data-route-name="/orders">
                    <Icons.orders />
                    <span className="p1 asidep asideActive">{t.orders}</span>
                </div>
                </Link>

                <Link to="/sold" className="Link">
                <div className="asideItem d-flex align-items-center" onClick={handle_asideItems} id="soldItem" data-route-name="/sold">
                    <Icons.transaction />
                    <span className="p1 asidep">{t.sold}</span>
                </div>
                </Link>

                <Link to="/page-design" className="Link">
                <div className="asideItem d-flex align-items-center" onClick={handle_asideItems} id="pageDesignItem" data-route-name="/page-design">
                    <Icons.design />
                    <span className="p1 asidep asideActive">{t.design}</span>
                </div>
                </Link>
                
                <Link to="/discounts" className="Link">
                <div className="asideItem d-flex align-items-center" onClick={handle_asideItems} id="discountsItem" data-route-name="/discounts">
                    <Icons.discount />
                    <span className="p1 asidep asideActive">{t.discount}</span>
                </div>
                </Link>

                <Link to="/items" className="Link" onClick={()=> locale.changeLanguge("en") }>
                <div className="asideItem d-flex align-items-center" onClick={handle_asideItems} id="itemsItem" data-route-name="/items">
                    <Icons.items />
                    <span className="p1 asidep asideActive">{t.items}</span>
                </div>
                </Link>

                <Link to="/page" className="Link" onClick={()=> locale.changeLanguge("de") }>
                <div className="asideItem d-flex align-items-center" onClick={handle_asideItems} id="PageItem" data-route-name="/page">
                    <Icons.page />
                    <span className="p1 asidep asideActive">{t.page}</span>
                </div>
                </Link>

            </div>
        </aside>
    )
}