import './discounts.css';
import { useContext, useState } from "react";
import { Containery } from "../../components/global/Container/container";
import localeContext from "../../locale/localeContext";
import Button from '../../components/global/button/Button';
import { BackDrop } from '../../components';
// MUI
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';




const Discounts = () => {
    const [ openAddDiscount, setOpenAddDiscount ] = useState(false);

    const locale = useContext(localeContext);
    const t_title = locale.selectedLanguge.aside.discount;




    return (
        <Containery>
            
            <AddDiscount open={openAddDiscount} closeHandler={setOpenAddDiscount} />
            
            <div className="d-flex justify-content-between align-items-center">
                <p className="p2 titleP500"> {t_title} </p>
                <Button className='!text-white' onClick={()=> setOpenAddDiscount(true)} > Erstellen </Button>
            </div>

            <div className='discounts-items-container grid'>
                <DiscountedItem itemTitle={"Pizza Salami"} discountType={"Percentage: 20%"} financial={2.05} totalSold={235} expires={"10-20-2023"} image={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuXsrGEI_Xd7_I4Dzch6VpGEJqKnq8im4cNBiTuqPnllczHkDk96LhaJg6dMQr9rJlMKs&usqp=CAU"} />
                <DiscountedItem itemTitle={"Pizza Margarita"} discountType={"Percentage: 20%"} financial={2.05} totalSold={235} expires={"10-20-2023"} image={"https://www.lieferando.de/foodwiki/uploads/sites/8/2018/01/pizza-3.jpg"} />
                <DiscountedItem itemTitle={"Pizza Spinat"} discountType={"Percentage: 20%"} financial={2.05} totalSold={235} expires={"10-20-2023"} image={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuXsrGEI_Xd7_I4Dzch6VpGEJqKnq8im4cNBiTuqPnllczHkDk96LhaJg6dMQr9rJlMKs&usqp=CAU"} />
                <DiscountedItem itemTitle={"Pizza Clasico"} discountType={"Percentage: 20%"} financial={2.05} totalSold={235} expires={"10-20-2023"} image={"https://www.lieferando.de/foodwiki/uploads/sites/8/2018/01/pizza-3.jpg"} />
                <DiscountedItem itemTitle={"Pizza Hawai"} discountType={"Percentage: 20%"} financial={2.05} totalSold={235} expires={"unlimted"} />
            </div>

        </Containery>
    );
}

export default Discounts;



function DiscountedItem(p) {
    return(
        <div className='discount-item-container'>
            <div className='discount-item-body flex flex-row-reverse justify-between'>
                
                { p.image && 
                <div className='discount-image-container'>
                    <img src={p.image} alt="" className='w-full' />
                </div>
                }

                <div className='flex-1'>
                    <div className='mb-3'>
                        <p className='p0 mb-1 opacity-80'>Item Title:</p>
                        <p className='p4 titleP500'> {p.itemTitle} </p>
                    </div>

                    <div className='mb-3'>
                        <p className='p0 mb-1 opacity-80'>Discount Type:</p>
                        <p className='p4 titleP500'> {p.discountType}  </p>
                    </div>

                    <div className='mb-3'>
                        <p className='p0 mb-1 opacity-80'>Financial value:</p>
                        <p className='p4 titleP500'> - {p.financial} $ </p>
                    </div>

                    <div className='mb-3'>
                        <p className='p0 mb-1 opacity-80'>Total Sold:</p>
                        <p className='p4 titleP500'> {p.totalSold} item </p>
                    </div>

                    <div className='mb-3'>
                        <p className='p0 mb-1 opacity-80'>Expires on:</p>
                        <p className='p4 titleP500'> {p.expires} </p>
                    </div>

                </div>

            </div>

            <div className='discounts-actions flex opacity-90'>
                <Button className='discount-edit-btn !text-white'> Edit </Button>
                <Button className='ml-2 flex-1 !bg-red-700 !text-white'> Delete </Button>
            </div>
        </div>
    )
}





function AddDiscount(p) {
    const [discountOn, setDiscountOn] = useState(1);
    const [selectedItem, setSelectedItem] = useState(1);

  


    return (
        <BackDrop open={p.open} closeHandler={p.closeHandler} >
            <div className='add-discount-container'>
                
                <div className='mb-4'>
                    <p className='p3 titleP500'> Discount on </p>

                    <div>
                        <Select id="select-discount-on" value={discountOn} label=" " onChange={(e)=>setDiscountOn(e.target.value)} className='w-full select-input-mui mt-2' >
                            <MenuItem value={1}>Artikel</MenuItem>
                            <MenuItem value={2}>Category</MenuItem>
                        </Select>
                    </div>

                </div>

                { discountOn == 1 &&
                    <div className='mb-4'>
                        <p className='p3 titleP500'> Select Item </p>

                        <div>
                            <Select id="select-discount-on" value={selectedItem} label=" " onChange={(e)=>setSelectedItem(e.target.value)} className='w-full select-input-mui mt-2' >
                                <MenuItem value={1}>Item 1</MenuItem>
                                <MenuItem value={2}>Item 2</MenuItem>
                                <MenuItem value={3}>Item 3</MenuItem>
                            </Select>
                        </div>

                    </div>
                }


            </div>
       </BackDrop>   
    )
}