import React, { createContext, useEffect, useState } from 'react';
// locale
import en from './en.json';
import de from './de.json';


const localeContext = createContext();


export const LocaleProvider = ({children}) => {
    const [ selectedLanguge, setSelectedLanguge ] = useState(en);
    const [state , setState] = useState({
        en,
        de
    });

    const changeLanguge = (lang) => {
        switch (lang) {
            case "en": setSelectedLanguge(en); break;
            case "de": setSelectedLanguge(de); break;
            default: break;
        }
    }


    return (
        <localeContext.Provider value={{get: state, selectedLanguge: selectedLanguge, changeLanguge }}>
            { children }
        </localeContext.Provider>
    );
}


export default localeContext;