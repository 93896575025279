import './backDrop.css';
import { useEffect, useRef } from "react";



const BackDrop = (p) => {
    const backDropContainer = useRef();
    const backDropBody = useRef();
    
    function handleClose() {
        backDropContainer.current.style.opacity = 0;
        backDropBody.current.style.transform = "translateY(70px) scale(0.9)";

        setTimeout(() => backDropContainer.current.classList.add("hidden") , 250);
        p.closeHandler(false);
    }

    useEffect(()=>{
        if (p.open) {
            backDropContainer.current.classList.add("flex") ;
            backDropContainer.current.classList.remove("hidden") ;
            setTimeout(() => {
                backDropContainer.current.style.opacity = 1;
                backDropBody.current.style.transform = "translateY(0) scale(1)";
            }, 10);
        }
    },[p.open]);

    return (
        <div className={"back-drop-container justify-center items-center hidden"} id="back-drop-container" ref={backDropContainer}>
            <div className="back-drop" id="back-drop" onClick={handleClose}></div>
            <div className="back-drop-body w-max h-max scale-50" id="back-drop-body" ref={backDropBody}>
                { p.children }
            </div>
        </div>
    );
}

export default BackDrop;




