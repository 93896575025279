import './orders.css';
import { useContext, useEffect } from "react";
import { Containery } from "../../components/global/Container/container";
// locale
import localeContext from '../../locale/localeContext';
import { OrderCard, OrderDetails } from '../../components';
import Icons from '../../assets/icons';

const Orders = () => {
    const locale = useContext(localeContext);
    const t_title = locale.selectedLanguge.aside.orders;


    return (
        <div className='orders-page'>

            <div className="d-flex justify-content-between align-items-center">
                <p className="p2 titleP500"> {t_title} </p>
            </div>


            <div className='orders-container'>
                <div className='orders-body flex justify-between'>

                    <div className='orders-cards grid'>
                        <OrderCard tableColor="#0351C1" orderNumber={110} totalItems={2} tableNumber={3} />
                        <OrderCard tableColor="#51EAFF" orderNumber={115} totalItems={1} tableNumber={1} />
                        <OrderCard tableColor="#45D09E" orderNumber={116} totalItems={5} tableNumber={2} />
                        <OrderCard tableColor="#0351C1" orderNumber={117} totalItems={3} tableNumber={3} />
                        <OrderCard tableColor="#45D09E" orderNumber={118} totalItems={2} tableNumber={2} />
                        <OrderCard tableColor="#35D073" orderNumber={119} totalItems={2} tableNumber={4} />
                        <OrderCard tableColor="#F85C50" orderNumber={122} totalItems={1} tableNumber={8} />
                        <OrderCard tableColor="#F85C50" orderNumber={125} totalItems={3} tableNumber={8} />
                        <OrderCard tableColor="#F85C50" orderNumber={128} totalItems={8} tableNumber={8} />
                    </div>

                    <OrderDetails />
                </div>

                <div className='orders-tabs-container'>
                    <div className='orders-tabs-body flex'>

                        <div className='orders-tab-btn flex justify-center items-center orders-tab-btn-active'> <button className='p0 w-max'> Current </button> </div>
                        <div className='orders-tab-btn flex justify-center items-center'> <button className='p0 w-max '> Last orders </button> </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default Orders;


