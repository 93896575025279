import Icons from "../../../assets/icons";

const OrderDetails = () => {
    return (
        <div className='order-details-container flex flex-col'>
            <div className='order-det-head flex justify-between items-center'>
                <div className='flex items-center p-3'>
                    <Icons.tisch />
                    <p className='p3 titleP500 !ml-2'>Table:  <span>5</span> </p>
                </div>

                <div className="h-full">
                    <button className='order-removebtn-container flex justify-center items-center'>
                       <Icons.trash />
                    </button>
                </div>
            </div>


            <div className='order-det-body scrollbar-none flex-1'>
                <OrdersList orderNumber={115} />
                <OrdersList orderNumber={115} />
            </div>


            <div className="w-full order-ready-btn">
                <button className="p3 titleP700"> Order Ready </button>
            </div>
        </div>
    );
}

export default OrderDetails;





function OrdersList(p) {
    return(
        <div className='order-detail'>
            <div className=" pb-3 ">
                <p className="p4 titleP500 underline"> Number: { p.orderNumber } </p>
            </div>

            <div>
                <OrderDetail />
                <OrderDetail />
                <OrderDetail />
                <OrderDetail />
            </div>
        </div>
    )
}


function OrderDetail(p) {

    return(
        <div className="order-detail-item">
            <p className="p6 detail-item-p titleP500"> Chasse Burger Mit Pomes </p>
            <div className="p-3 pb-0">
                <p className="p3 w-max mb-2"> -  Süß Kartoffle </p>
                <p className="p3 w-max mb-2"> -  Süß Kartoffle </p>
            </div>
        </div>
    )
}