import React from "react";

export const Containery = (props) =>{

    
    return (
        <div className={"containery " + props.className}>
            { props.children }
        </div>
    )
}