import React, { useEffect, useState } from 'react';
import './Page.css';

const Page = () => {

    return (
        <div className="PageMainContainer">
            
        </div>
    );
}

export default Page;