import './style/Normalize/Normalize.css';
import './style/global/main.css';
import './style/global/colors.css';
import './style/global/odeTable.css';
import { BrowserRouter as Router , Routes , Route } from 'react-router-dom';
// Screens
import { Home } from './screens/Home/Home';
import { Analyse } from './screens/analyse/Analyse';
import { LogIn } from './screens/logIn/logIn';
import Page from './screens/page/Page';
import Orders from './screens/Orders/Orders';
import SoldPage from './screens/SoldPage/SoldPage';
import Discounts from './screens/Discounts/Discounts';
import Items from './screens/Items/Items';
import Design from './screens/Design/Design';
import DiscountEditor from './screens/Discounts/DiscountEditor/DiscountEditor';
// Components
import { Aside } from './components/global/aside/Aside';
import { MainHeader } from './components/global/header/Header';
import React , { useState , useEffect} from 'react';
import { Drawer } from './components/global/Drawer/Drawer'
// Context
import { MainProvider } from './context/main';
import { LocaleProvider } from './locale/localeContext';
// redux
import MainStore from './redux/store';
import { Provider } from 'react-redux';




function App() {
  const [ loggedIn , setlLoggedIn ] = useState(true);

  useEffect(()=>{
    getDarkMod();
  },[]);

  const lLoggedIn = function(){
    const EmailLoginInput = document.getElementById("EmailLoginInput");
    const PasswordLoginInput = document.getElementById("PasswordLoginInput");
    const RememberCheckbox = document.getElementById("RememberCheckbox");
    setlLoggedIn(true);
  }
  if (!loggedIn) { return(<LogIn logIn={()=>lLoggedIn()} />)}

  function getDarkMod() {
    let storage;
    try{ storage = JSON.parse(localStorage.getItem("dark")) }
    finally{ storage ? document.documentElement.classList.add("darkMood") : document.documentElement.classList.remove("darkMood");  }
  }




  return (
    <Provider store={MainStore}>
      <LocaleProvider>
      <MainProvider >
        <Router>
            <div className="App d-flex justify-content-between" >
              <Aside />
              <div className="mainContainer" id='mainContainer'>
                <MainHeader /> <Drawer />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="*" element={<Home />} />
                  <Route path="/page" element={<Page />} />
                  <Route path="/analyse" element={<Analyse />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/sold" element={<SoldPage />} />
                  <Route path="/discounts" element={<Discounts />} />
                  <Route path="/items" element={<Items />} />
                  <Route path="/page-design" element={<Design />} />
                  <Route path="/discounts/editor" element={<DiscountEditor />} />
                </Routes>
              </div>
            </div>
        </Router>
      </MainProvider>
      </LocaleProvider>
    </Provider>
  );
}
export default App;
