import React, { useEffect , useState } from "react";
import './Home.css';

import { Containery } from "../../components/global/Container/container";
import { InfoCard } from "../../components/privte/infoCard/InfoCard";
import ReactApexChart from "react-apexcharts";
import Button from "../../components/global/button/Button";
import { useSelector } from "react-redux";



export const Home = () => {
    const state = useSelector(state => state);
    
    const [ chartsState , setChartsState ] = useState({
        series: [{
          name: 'umsatz',
          data: [301, 400, 208, 601, 820, 1009, 1000, 1050, 800, 650, 701, 802, 1009, 1000]
        }],
        options: {
          chart: {
            height: 350,
            width: "100%",
            type: 'area',
            foreColor: '#000'
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            type: 'category',
            categories: ["2018-09-19", "2018-09-19", "2018-09-19", "2018-09-19", "2018-09-19", "2018-09-19", "2018-09-19", "2018-09-19", "2018-09-19", "2018-09-19", "2018-09-19", "2018-09-19", "2018-09-19", "2018-09-19"]
          },
          fill: {
            colors: ['#3441D4']
          },
          stroke: {
            curve: 'smooth',
            colors: ['#3441D4']
          },
          tickPlacement: 'between',
          tooltip: {
            x: {
              format: 'yy/MM/dd'
            },
          },
        },
      
    });

    


















    return(
        <div className="HomePage " id="HomePage">
            <Containery>
                <div>
                    <p className="p2 titleP500">Over view</p>
                    <div className="gridContainer gridsec1">
                        <InfoCard
                            title="TOTAL EARNINGS" 
                            updown="+16.24 %" 
                            value = "$559.25k"
                            linkTitle = "View net earnings"
                            icon = {<svg className="inforCardICon" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <circle cx="12" cy="12" r="9" /> <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1" /> <path d="M12 6v2m0 8v2" /> </svg>}
                        />

                        <InfoCard 
                            title="ORDERS" 
                            updown="-3.57 %" 
                            value = "36,894"
                            linkTitle = "View all orders"
                            icon = {<svg xmlns="http://www.w3.org/2000/svg" className="inforCardICon" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <polyline points="7 10 12 4 17 10" />
                            <path d="M21 10l-2 8a2 2.5 0 0 1 -2 2h-10a2 2.5 0 0 1 -2 -2l-2 -8z" />
                            <circle cx="12" cy="15" r="2" />
                          </svg>}
                        />

                        <InfoCard 
                            title="CUSTOMERS" 
                            updown="+29.08 %" 
                            value = "183.35M"
                            linkTitle = "See details"
                            icon = {<svg xmlns="http://www.w3.org/2000/svg" className="inforCardICon" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <circle cx="9" cy="7" r="4" />
                            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                            <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                          </svg>}
                        />

                        <InfoCard 
                            title="TOTAL EARNINGS" 
                            updown="16.24 %" 
                            value = "$559.25k"
                            linkTitle = "View net earnings"
                            icon = {<svg xmlns="http://www.w3.org/2000/svg" className="inforCardICon" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <circle cx="12" cy="12" r="9" /> <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1" /> <path d="M12 6v2m0 8v2" /> </svg>}
                        />
                    </div>
                </div>

                <div style={{marginTop: '20px'}}>
                    
                    <div className="HomeChartscontainer gridContainer">
                      <div className="chartsContainer cardContainer">
                        <div className="cardContainerHeader">
                            <p className="p2" style={{opacity: 0.7}}>UMSATZ ÜBERBLICK</p>
                        </div>
                        <div className="listInline d-flex justify-content-center align-content-center">
                            <div className="listInlineItem d-flex justify-content-center align-items-center">
                              <p className="p0"> <span className="primaryColor">$584k</span> Revenue</p>
                            </div>
                            <div className="listInlineItem d-flex justify-content-center align-items-center">
                              <p className="p0"> <span className="primaryColor">$497k</span> Expenses</p>
                            </div>
                        </div>
                        <ReactApexChart options={chartsState.options} series={chartsState.series} type="area"  height={300} />
                      </div>
                    </div>
                </div>

                <div className="TableContainer">
                  <div> <p className="p3 titleP500" > Lastest Transaction  </p> </div>

                  <div style={{overflowY: "auto"}}> 
                    <div className="ode_table">

                      <div className="ode_tableHead">
                        <div className="ode_tr d-flex align-items-center justify-content-start">
                          <div className="ode_td flex-grow-1 p2 titleP500"> Order ID </div>
                          <div className="ode_td flex-grow-1 p2 titleP500"> Billing Name </div>
                          <div className="ode_td flex-grow-1 p2 titleP500"> Date </div>
                          <div className="ode_td flex-grow-1 p2 titleP500"> Payment Status </div>
                          <div className="ode_td flex-grow-1 p2 titleP500"> Task </div>
                          <div className="ode_td flex-grow-1 p2 titleP500"> Total </div>
                          <div className="ode_td flex-grow-1 p2 titleP500"> View Details </div>
                        </div>
                      </div>

                      <div className="ode_tableBody">
                        <div className="ode_tr d-flex align-items-center justify-content-start">
                          <div className="ode_td flex-grow-1 p1-5 titleP500"> #1257 </div>
                          <div className="ode_td flex-grow-1 p1-5"> Neal Matthews </div>
                          <div className="ode_td flex-grow-1 p1-5"> 07 Oct, 2021 </div>
                          <div className="ode_td flex-grow-1 p1-5"> <div className="p1 status badgeSoftDanger">Chargeback</div> </div>
                          <div className="ode_td flex-grow-1 p1-5"> $5 </div>
                          <div className="ode_td flex-grow-1 p1-5"> $400 </div>
                          <div className="ode_td flex-grow-1 p1-5"> <Button>View Details</Button> </div>
                        </div>

                        <div className="ode_tr d-flex align-items-center justify-content-start">
                          <div className="ode_td flex-grow-1 p1-5 titleP500"> #1257 </div>
                          <div className="ode_td flex-grow-1 p1-5"> Neal Matthews </div>
                          <div className="ode_td flex-grow-1 p1-5"> 07 Oct, 2021 </div>
                          <div className="ode_td flex-grow-1 p1-5"> <div className="p1 status badgeSoftSuccess">Poid</div> </div>
                          <div className="ode_td flex-grow-1 p1-5"> $5 </div>
                          <div className="ode_td flex-grow-1 p1-5"> $400 </div>
                          <div className="ode_td flex-grow-1 p1-5"> <Button>View Details</Button> </div>
                        </div>


                        <div className="ode_tr d-flex align-items-center justify-content-start">
                          <div className="ode_td flex-grow-1 p1-5 titleP500"> #1257 </div>
                          <div className="ode_td flex-grow-1 p1-5"> Neal Matthews </div>
                          <div className="ode_td flex-grow-1 p1-5"> 07 Oct, 2021 </div>
                          <div className="ode_td flex-grow-1 p1-5"> <div className="p1 status badgeSoftSuccess">Poid</div> </div>
                          <div className="ode_td flex-grow-1 p1-5"> $5 </div>
                          <div className="ode_td flex-grow-1 p1-5"> $400 </div>
                          <div className="ode_td flex-grow-1 p1-5"> <Button>View Details</Button> </div>
                        </div>


                        <div className="ode_tr d-flex align-items-center justify-content-start">
                          <div className="ode_td flex-grow-1 p1-5 titleP500"> #1257 </div>
                          <div className="ode_td flex-grow-1 p1-5"> Neal Matthews </div>
                          <div className="ode_td flex-grow-1 p1-5"> 07 Oct, 2021 </div>
                          <div className="ode_td flex-grow-1 p1-5"> <div className="p1 status badgeSoftSuccess">Poid</div> </div>
                          <div className="ode_td flex-grow-1 p1-5"> $5 </div>
                          <div className="ode_td flex-grow-1 p1-5"> $400 </div>
                          <div className="ode_td flex-grow-1 p1-5"> <Button>View Details</Button> </div>
                        </div>

                        <div className="ode_tr d-flex align-items-center justify-content-start">
                          <div className="ode_td flex-grow-1 p1-5 titleP500"> #1257 </div>
                          <div className="ode_td flex-grow-1 p1-5"> Neal Matthews </div>
                          <div className="ode_td flex-grow-1 p1-5"> 07 Oct, 2021 </div>
                          <div className="ode_td flex-grow-1 p1-5"> <div className="p1 status badgeSoftWarning">Refund</div> </div>
                          <div className="ode_td flex-grow-1 p1-5"> $5 </div>
                          <div className="ode_td flex-grow-1 p1-5"> $400 </div>
                          <div className="ode_td flex-grow-1 p1-5"> <Button>View Details</Button> </div>
                        </div>

                        <div className="ode_tr d-flex align-items-center justify-content-start">
                          <div className="ode_td flex-grow-1 p1-5 titleP500"> #1257 </div>
                          <div className="ode_td flex-grow-1 p1-5"> Neal Matthews </div>
                          <div className="ode_td flex-grow-1 p1-5"> 07 Oct, 2021 </div>
                          <div className="ode_td flex-grow-1 p1-5"> <div className="p1 status badgeSoftDanger">Chargeback</div> </div>
                          <div className="ode_td flex-grow-1 p1-5"> $5 </div>
                          <div className="ode_td flex-grow-1 p1-5"> $400 </div>
                          <div className="ode_td flex-grow-1 -5"> <Button>View Details</Button> </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
            </Containery>
        </div>
    )
}


