import './items.css';
import { useContext, useState } from "react";
import { Containery } from "../../components/global/Container/container";
import localeContext from '../../locale/localeContext';
import Button from '../../components/global/button/Button';

const Items = () => {

    const locale = useContext(localeContext);
    const t_title = locale.selectedLanguge.aside.items;


    return (
        <Containery>
            <div className="d-flex justify-content-between align-items-center">
                <p className="p2 titleP500"> {t_title} </p>
                <Button className='!text-white' > Add Item </Button>
            </div>

            <div className='items-page-container'>
                <ItemsTableContainer />
            </div>
        </Containery>
    );
}

export default Items;


function ItemsTableContainer() {

    const [ seletedCategoryID, setSeletedCategoryID ] = useState(1);

    return(
        <div className="TableContainer">
            <div className='items-categoris-container flex items-center scrollbar-none'>  
                <div className={ seletedCategoryID == 1 ? 'items-cate-item flex items-center justify-center items-cate-item-active' : 'items-cate-item flex items-center justify-center'}> <p className='p1'> All </p> </div>
                <div className='items-cate-item flex items-center justify-center'> <p className='p1'> Pizza </p> </div>
                <div className='items-cate-item flex items-center justify-center'> <p className='p1'> Burger </p> </div>
                <div className='items-cate-item flex items-center justify-center'> <p className='p1'> Dessert </p> </div>
                <div className='items-cate-item flex items-center justify-center'> <p className='p1'> Drinks </p> </div>
            </div>

            <div style={{overflowY: "auto"}}> 
                <div className="ode_table">
                    <TableHead />

                    <div className="ode_tableBody">

                        <TableRow />
                        <TableRow />
                        <TableRow />
                        <TableRow />

                        <div className="ode_tr ode_tr_items d-flex align-items-center justify-content-start">
                            <div className="ode_td flex-grow-1 p1-5 titleP500"> #1257 </div>
                            <div className="ode_td flex-grow-1 p1-5"> Neal Matthews </div>
                            <div className="ode_td flex-grow-1 p1-5"> 07 Oct, 2021 </div>
                            <div className="ode_td flex-grow-1 p1-5"> <div className="p1 status badgeSoftSuccess">Poid</div> </div>
                            <div className="ode_td flex-grow-1 p1-5"> $5 </div>
                            <div className="ode_td flex-grow-1 p1-5"> $400 </div>
                            <div className="ode_td flex-grow-1 p1-5"> <Button>View Details</Button> </div>
                        </div>

                        <div className="ode_tr ode_tr_items d-flex align-items-center justify-content-start">
                            <div className="ode_td flex-grow-1 p1-5 titleP500"> #1257 </div>
                            <div className="ode_td flex-grow-1 p1-5"> Neal Matthews </div>
                            <div className="ode_td flex-grow-1 p1-5"> 07 Oct, 2021 </div>
                            <div className="ode_td flex-grow-1 p1-5"> <div className="p1 status badgeSoftWarning">Refund</div> </div>
                            <div className="ode_td flex-grow-1 p1-5"> $5 </div>
                            <div className="ode_td flex-grow-1 p1-5"> $400 </div>
                            <div className="ode_td flex-grow-1 p1-5"> <Button>View Details</Button> </div>
                        </div>

                        <div className="ode_tr ode_tr_items d-flex align-items-center justify-content-start">
                            <div className="ode_td flex-grow-1 p1-5 titleP500"> #1257 </div>
                            <div className="ode_td flex-grow-1 p1-5"> Neal Matthews </div>
                            <div className="ode_td flex-grow-1 p1-5"> 07 Oct, 2021 </div>
                            <div className="ode_td flex-grow-1 p1-5"> <div className="p1 status badgeSoftDanger">Chargeback</div> </div>
                            <div className="ode_td flex-grow-1 p1-5"> $5 </div>
                            <div className="ode_td flex-grow-1 p1-5"> $400 </div>
                            <div className="ode_td flex-grow-1 -5"> <Button>View Details</Button> </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

function TableHead() {
    return(
        <div className="ode_tableHead tableHead_sold">
        <div className="ode_tr ode_tr_items d-flex align-items-center justify-content-start">
            <div className="ode_td flex-grow-1 p2 titleP500"> Order ID </div>
            <div className="ode_td flex-grow-1 p2 titleP500"> Billing Name </div>
            <div className="ode_td flex-grow-1 p2 titleP500"> Date </div>
            <div className="ode_td flex-grow-1 p2 titleP500"> Payment Status </div>
            <div className="ode_td flex-grow-1 p2 titleP500"> Task </div>
            <div className="ode_td flex-grow-1 p2 titleP500"> Total </div>
            <div className="ode_td flex-grow-1 p2 titleP500"> View Details </div>
        </div>
        </div>
    )
}

function TableRow() {
    return(
        <div className="ode_tr  ode_tr_items d-flex align-items-center justify-content-start">
            <div className="ode_td flex-grow-1 p1-5 titleP500"> #1257 </div>
            <div className="ode_td flex-grow-1 p1-5"> Neal Matthews </div>
            <div className="ode_td flex-grow-1 p1-5"> 07 Oct, 2021 </div>
            <div className="ode_td flex-grow-1 p1-5"> <div className="p1 status badgeSoftSuccess">Poid</div> </div>
            <div className="ode_td flex-grow-1 p1-5"> $5 </div>
            <div className="ode_td flex-grow-1 p1-5"> $400 </div>
            <div className="ode_td flex-grow-1 p1-5"> <Button>View Details</Button> </div>
        </div>
    )
}