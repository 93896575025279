import './design.css';
import { useContext, useState } from "react";
import { Containery } from "../../components/global/Container/container";
import localeContext from '../../locale/localeContext';
import Button from '../../components/global/button/Button';



const Design = () => {
    
    const locale = useContext(localeContext);
    const t_title = locale.selectedLanguge.aside.design;

    return (
        <Containery>
            <div className="d-flex justify-content-between align-items-center">
                <p className="p2 titleP500"> {t_title} </p>
                <Button className='!text-white' > Template </Button>
            </div>

            <div className='design-page-container'>

            </div>
        </Containery>
    );
}

export default Design;